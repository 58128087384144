import './App.css';
import Navbar from './component/navbar';
import Hero from './component/hero';
import Intro from './component/Intro';
import Main from './component/main';
import Product from './component/other_product';
import Advertisement from './component/advertisement';
import Location from './component/location'
import Footer from './component/footer'
import AboutUs from './component/aboutUs';
import Use from './component/use';
import UseTitle from './component/useTitle';
function App() {
  return (
    <div className="App">
     <Navbar/>
     <Hero/>
     <Intro/>
     <UseTitle/>
     <Use/>
     <Main/>
     <Advertisement/>
     <Product/>
     <Location/>
     <AboutUs/>
     <Footer/>
    </div>
  );
}

export default App;
