import React from 'react';
import '../style/hero.css';
import { test, heroAdapt, mediumHero,largeHero,xlarge } from '../asset';

const Hero = () => {
  const handleScrollToMain = (e) => {
    e.preventDefault();
    const mainSection = document.getElementById('main_container');
    if (mainSection) {
      mainSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="hero_container">
      <div className="hero_right">
      <img  className="xlargeScreen" src={xlarge} alt="product" />
      <img  className="largeScreen" src={largeHero} alt="product" />
      <img  className="mediumScreen" src={mediumHero} alt="product" />
      <img  className="normalScreen" src={test} alt="product" />
      <img  className="smallScreen" src={heroAdapt} alt="product" />
      </div>
      <div className="hero_button">
        <a href="#main_container" onClick={handleScrollToMain}>Shop Now</a>
      </div>
    </div>
  );
}

export default Hero;
