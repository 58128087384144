import React, { useState, useRef } from 'react';
import '../style/main.css';
import { photo1 , base_model_1,base_model_2,girofar_enable,girofar_disable,girofar2_enable,girofar2_disable,first,second,third,first_2nd,second_2nd,third_2nd, video,Loading,video2,photo2nd,Classic2,advanced2,base_model_1_2nd} from '../asset';

const Main = () => {
  const [loading, setLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedSound, setSelectedSound] = useState(null);
  const [audioSource, setAudioSource] = useState(null);
  const [selectedWire, setSelectedWire] = useState(null);
  const [selectedGirofar, setSelectedGirofar] = useState(null);
  const [selectedBattery, setSelectedBattery] = useState(null);
  const [selectedSolarPanel, setSelectedSolarPanel] = useState(null);
  const [selectedInstallation, setSelectedInstallation] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedPostage, setSelectedPostage] = useState(null);
  const [selectedCodes, setSelectedCodes] = useState('');
  const [ModelSelection, setModelSelection] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [productImage, setProductImage] = useState(photo2nd);

  const imageRef = useRef(null);

  const handleModelClick = (model) => {
    setLoading(true); // Set loading to true when starting to load the image
    setProductImage(null);
    setSelectedModel(model);
    setSelectedSound(null);
    setSelectedWire(null);
    setSelectedGirofar(null);

    if (model === 'Model1') {
      setProductImage(Classic2);
      setSelectedVideo(null);
    } else if (model === 'Model2') {
      setProductImage(advanced2);
      setSelectedVideo(null);
    }
    
    // Simulate an image loading delay (you can replace this with actual image loading logic)
    setTimeout(() => {
      setLoading(false); // Set loading to false when the image has loaded
      if (imageRef.current) {
        imageRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300); // Adjust the timeout duration as needed
  };


  const handleSoundClick = (sound) => {
    setSelectedSound(sound);

    let newAudioSource = null;

    if (sound === 'Model1_sound1') {
      newAudioSource = new Audio(first_2nd);
    } else if (sound === 'Model1_sound2') {
      newAudioSource = new Audio(second_2nd);
    } else if (sound === 'Model1_sound3') {
      newAudioSource = new Audio(third_2nd);
    }else if (sound === 'Model2_sound1') {
      newAudioSource = new Audio(first);
    }else if (sound === 'Model2_sound2') {
      newAudioSource = new Audio(second);
    }else if (sound === 'Model2_sound3') {
      newAudioSource = new Audio(third);
    }


    if (audioSource) {
      audioSource.pause();
    }

    // Set the new audio source
    setAudioSource(newAudioSource);

    // Play the new audio source
    if (newAudioSource) {
      newAudioSource.play();
    }else {
      audioSource.pause();
    }
  };

  const handleWireClick = (wire) => {
    setSelectedWire(wire);
  };

  const handleGirofarClick = (girofar) => {
    setLoading(true); // Set loading to true when starting to load the image
    setSelectedGirofar(girofar);

    if (girofar === 'Girofar_Enable') {
      setProductImage(base_model_1_2nd);
      setSelectedVideo(null);
    } else if (girofar === 'Girofar_Disable') {
      setProductImage(Classic2);
      setSelectedVideo(null);
    } else if (girofar === 'Girofar2_Enable') {
      setProductImage(photo2nd);
      setSelectedVideo(null);
    } else if (girofar === 'Girofar2_Disable') {
      setProductImage(advanced2);
      setSelectedVideo(null);
    }

    // Simulate an image loading delay (you can replace this with actual image loading logic)
    setTimeout(() => {
      setLoading(false); // Set loading to false when the image has loaded
      if (imageRef.current) {
        imageRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300); // Adjust the timeout duration as needed
  };
  const handleBatteryClick = (battery) => {
    setSelectedBattery(battery);
  };

  const handleSolarPanelClick = (solarPanel) => {
    setSelectedSolarPanel(solarPanel);
  };

  const handleInstallationClick = (installation) => {
    setSelectedInstallation(installation);
  };

  const handleVideoClick = (video2) => {
    
    if (video2 === 'Video') {
      setSelectedVideo(video2);
    }
    if (imageRef.current) {
      imageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handlePostageClick = (postage) => {
    setSelectedPostage(postage);
  };

  const getCategoryButtonStyle = (category, option) => {
    switch (category) {
      case 'model':
        return {
          backgroundColor: selectedModel === option ? 'orange' : '',
          color: selectedModel === option ? 'white' : '',
        };
      // case 'sound':
      //   return {
      //     backgroundColor: selectedSound === option ? 'white' : '',
      //     color: selectedSound === option ? 'white' : '',
      //   };
      case 'wire':
        return {
          backgroundColor: selectedWire === option ? 'orange' : '',
          color: selectedWire === option ? 'white' : '',
        };
      case 'girofar':
        return {
          backgroundColor: selectedGirofar === option ? 'orange' : '',
          color: selectedGirofar === option ? 'white' : '',
        };
      case 'battery':
        return {
          backgroundColor: selectedBattery === option ? 'orange' : '',
          color: selectedBattery === option ? 'white' : '',
        };
      case 'solarPanel':
        return {
          backgroundColor: selectedSolarPanel === option ? 'orange' : '',
          color: selectedSolarPanel === option ? 'white' : '',
        };
      case 'installation':
        return {
          backgroundColor: selectedInstallation === option ? 'orange' : '',
          color: selectedInstallation === option ? 'white' : '',
        };
      // case 'video':
      //   return {
      //     backgroundColor: selectedVideo === option ? 'green' : '',
      //     color: selectedVideo === option ? 'white' : '',
      //   };
      case 'postage':
        return {
          backgroundColor: selectedPostage === option ? 'orange' : '',
          color: selectedPostage === option ? 'white' : '',
        };
      default:
        return {};
    }
  };

  const getModelMessage = () => {
    switch (selectedModel) {
      case 'Model1':
        return (
          <><p>You selected <b>Classic</b>. This model offers an <b>internal</b> alarm sound with other various customization.</p><p><b>include:</b> 1 adapter, 1 remote (1 additional remote FREE!!)</p><p>Base Price: <b>Rs 3215</b></p></>
        );
      case 'Model2':
        return (
          <><p>You selected <b>Advanced</b>. This model offers an <b>external</b> alarm sound with other various customization.</p><p><b>include:</b> 1 adapter, 1 remote (1 additional remote FREE!!)</p><p>Base Price: <b>Rs 3360</b></p></>
        );
      default:
        return '';
    }
  };
  const getSoundMessage = () => {
    switch (selectedSound) {
      case 'Model2_sound1':
        return (
          <p>You selected <b>1 meter</b>. Hear sound of the alarm from 1 meter. This sound comes from internal alarm sound.</p>
        );
      case 'Model2_sound2':
        return (
          <p>You selected <b>5 meter</b>. Hear sound of the alarm from 5 meter. This sound comes from internal alarm sound.</p>
        );
      case 'Model2_sound3':
      return (
        <p>You selected <b>10 meter</b>. Hear sound of the alarm from 10 meter. This sound comes from internal alarm sound.</p>
      );
      case 'Model1_sound1':
        return (
          <p>You selected <b>1 meter</b>. Hear sound of the alarm from 1 meter. This sound comes from external alarm sound.</p>
        );
      case 'Model1_sound2':
        return (
          <p>You selected <b>5 meter</b>. Hear sound of the alarm from 5 meter. This sound comes from external alarm sound.</p>
        );
      case 'Model1_sound3':
      return (
        <p>You selected <b>10 meter</b>. Hear sound of the alarm from 10 meter. This sound comes from external alarm sound.</p>
      );
      default:
        return '';
    }
  };

  const getWireMessage = () => {
    switch (selectedWire) {
      case 'Model2_wire1':
        return (
          <><p>You selected <b>Wire1</b>. This option includes a shorter wire length.</p><p>Add-on Price: <b>Rs 0</b></p></>
        );
      case 'Model2_wire2':
        return (
          <><p>You selected <b>Wire1</b>. This option includes a shorter wire length.</p><p>Add-on Price: <b>Rs 15</b></p></>
        );
      case 'Model2_wire3':
        return (
          <><p>You selected <b>Wire1</b>. This option includes a shorter wire length.</p><p>Add-on Price: <b>Rs 45</b></p></>
        );
      case 'Model2_wire4':
        return (
          <><p>You selected <b>Wire1</b>. This option includes a shorter wire length.</p><p>Add-on Price: <b>Rs 75</b></p></>
        );
      case 'Model2_wire5':
        return (
          <><p>You selected <b>Wire1</b>. This option includes a shorter wire length.</p><p>Add-on Price: <b>Rs 105</b></p></>
        );
      case 'Model2_wire6':
        return (
          <><p>You selected <b>Wire1</b>. This option includes a shorter wire length.</p><p>Add-on Price: <b>Rs 150</b></p></>
        );
      default:
        return '';
    }
  };
  

  const getGirofarMessage = () => {
    switch (selectedGirofar) {
      case 'Girofar_Enable':
        return (
         <><p>You selected <b>Girofar Enabled</b>. This option <b>does not includes</b> the girofar feature.</p><p>Add-on Price: <b>Rs 225</b></p></> 
        );
      case 'Girofar_Disable':
        return (
          <><p>You selected <b>Girofar Enabled</b>. This option <b>includes</b> the girofar feature.</p><p>Add-on Price: <b>Rs 0</b></p></> 
        );
      
      default:
        return '';
    }
  };

  const getBatteryMessage = () => {
    switch (selectedBattery) {
      case 'Battery_Enable':
        return (
         <><p>You selected <b>Battery Enabled</b>. This option <b>gives</b> an autonomy of <b>3</b> days without any electricity input.</p><p>Add-on Price: <b>Rs 450</b></p></> 
        );
      case 'Battery_Disable':
        return (
          <><p>You selected <b>Battery Disabled</b>. This option <b>does not includes</b> a rechargeable battery.</p><p>Add-on Price: <b>Rs 0</b></p></>
        );
      default:
        return '';
    }
  };

  const getSolarPanelMessage = () => {
    switch (selectedSolarPanel) {
      case 'Solar_Enable':
        return (
          <><p>You selected <b>Solar Panel Enabled</b>. This option <b>includes</b> a solar panel for power generation.</p><p>Add-on Price: Rs10</p></>
        );
      case 'Solar_Disable':
        return (
          <><p>You selected <b>Solar Panel Enabled</b>. This option <b>does not includes</b> a solar panel for power generation.</p><p>Add-on Price: Rs100</p></>
        );
      default:
        return '';
    }
  };

  const getInstallationMessage = () => {
    switch (selectedInstallation) {
      case 'Installation_Enable':
        return (
          <><p>You selected <b>Installation Enabled</b>. This option <b>includes</b> professional installation services.</p><p>Add-on Price: <b>Price is discussed after installation</b></p></>
        );
      case 'Installation_Disable':
        return (
          <><p>You selected <b>Installation Enabled</b>. This option <b>does not includes</b> professional installation services.</p><p>Add-on Price: <b>0</b></p></>
        );
      default:
        return '';
    }
  };

  const getVideoMessage = () => {
    switch (selectedVideo) {
      case 'Video':
        return (
          <p>View video of the product.</p>
        );
      default:
        return '';
    }
  };

  const getPostageMessage = () => {
    switch (selectedPostage) {
      case 'Postage_Enable':
        return (
          <><p>You selected <b>Postage Enabled</b>. This option includes postage services. Please send your address on whatsApp referring to the contact below. </p><p>Add-on Price: <b>Rs 120</b></p></>
        );
      case 'Postage_Disable':
        return (
          <><p>You selected <b>Postage Disabled</b>. This option does not includes postage services.</p><p><b>Pickup your purchased item in our store, location is found below</b></p></>
        );
      default:
        return '';
    }
  };
  const codeMappings = {
    'Model1': 'X',
    'Model2': 'Y',
    'Model2_wire1': 'F',
    'Model2_wire2': 'G',
    'Model2_wire3': 'F',
    'Model2_wire4': 'G',
    'Model2_wire5': 'F',
    'Model2_wire6': 'G',
    'Model2_wire7': 'F',
    'Model2_wire8': 'G',
    'Model2_wire9': 'G',
    'Girofar_Enable': 'H',
    'Girofar_Disable': 'I',
    'Battery_Enable': 'J',
    'Battery_Disable': 'K',
    'Solar_Enable': 'L',
    'Solar_Disable': 'M',
    'Installation_Enable': 'N',
    'Installation_Disable': 'O',
    'Postage_Enable': 'Q',
    'Postage_Disable': 'R',
  };

  const TextMappings = {
    'Model1': 'Adapter, Sound Alarm, Remote, ',
    'Model2': 'Adapter, External Sound Alarm, Remote, ',
    'Model2_wire1': '0.24 metres wire, ',
    'Model2_wire2': '1 metres wire, ',
    'Model2_wire3': '3 metres wire, ',
    'Model2_wire4': '5 metres wire, ',
    'Model2_wire5': '7 metres wire, ',
    'Model2_wire6': '10 metres wire, ',
    // 'Model2_wire7': '14 metres wire, ',
    // 'Model2_wire8': '17 metres wire, ',
    // 'Model2_wire9': '20 metres wire, ',
    'Girofar_Enable': 'Girofar, ',
    'Battery_Enable': 'Battery, ',
    'Installation_Enable': 'Installation, ',
    'Postage_Enable': 'Postage',
    
  };
  const prices = {
    Model1: 3215,
    Model2: 3360,
    Model1_sound1: 0, // You can assign prices to sound options if needed
    Model1_sound2: 0,
    Model1_sound3: 0,
    Model2_sound1: 0,
    Model2_sound2: 0,
    Model2_sound3: 0,
    Model2_wire1: 0,
    Model2_wire2: 15,
    Model2_wire3: 45,
    Model2_wire4: 75,
    Model2_wire5: 105,
    Model2_wire6: 150,
    Girofar_Enable: 225, // Add-on price for Girofar
    Girofar_Disable: 0,
    Battery_Enable: 450, // Add-on price for Battery
    Battery_Disable: 0,
    Solar_Enable: 0, // Add-on price for Solar Panel
    Solar_Disable: 0,
    Installation_Enable: 0, // Add-on price for Installation
    Installation_Disable: 0,
    Video: 0,
    Postage_Enable:120, // Add-on price for Postage
    Postage_Disable: 0,
  };
  
  const handleCalculateClick = () => {
    if (!selectedModel) {
      alert('Please select a model.');
      return;
    }
  

    if (selectedModel === 'Model2' && !selectedWire) {
      alert('Please select sound and wire options for Model2.');
      return;
    }
  
    // Add alerts for other cases
    if (!selectedGirofar) {
      alert('Please select a girofar option.');
      return;
    }
  
    if (!selectedBattery) {
      alert('Please select a battery option.');
      return;
    }
  
    // if (!selectedSolarPanel) {
    //   alert('Please select a solar panel option.');
    //   return;
    // }
  
    // if (!selectedInstallation) {
    //   alert('Please select an installation option.');
    //   return;
    // }
  
    if (!selectedPostage) {
      alert('Please select a postage option.');
      return;
    }
    // Concatenate the selected codes
    const concatenatedCodes =
    (codeMappings[selectedModel] || '') +
    (codeMappings[selectedSound] || '') +
    (codeMappings[selectedWire] || '') +
    (codeMappings[selectedGirofar] || '') +
    (codeMappings[selectedBattery] || '') +
    (codeMappings[selectedSolarPanel] || '') +
    (codeMappings[selectedInstallation] || '') +
    (codeMappings[selectedVideo] || '') +
    (codeMappings[selectedPostage] || '');
  

    const modelSelected =
    (TextMappings[selectedModel] || '') +
    (TextMappings[selectedSound] || '') +
    (TextMappings[selectedWire] || '') +
    (TextMappings[selectedGirofar] || '') +
    (TextMappings[selectedBattery] || '') +
    (TextMappings[selectedSolarPanel] || '') +
    (TextMappings[selectedInstallation] || '') +
    (TextMappings[selectedVideo] || '') +
    (TextMappings[selectedPostage] || '');
  


    let totalCost = prices[selectedModel] || 0;
    totalCost += prices[selectedWire] || 0;
    totalCost += prices[selectedGirofar] || 0;
    totalCost += prices[selectedBattery] || 0;
    totalCost += prices[selectedSolarPanel] || 0;
    totalCost += prices[selectedInstallation] || 0;
    totalCost += prices[selectedVideo] || 0;
    totalCost += prices[selectedPostage] || 0;

    setSelectedCodes(concatenatedCodes);
    setModelSelection(modelSelected);
    setSelectedPrice(totalCost);
    

  };

  return (
    <div id="main_container" className="main_container">
        <div className="main_left" ref={imageRef}>
        <h3>[NEW!!! REMOTE PANIC ALARM SYSTEM] </h3>
        {loading ? (
          <p>Loading...</p>
        ) : selectedVideo === 'Video' ? (
          <video className="video" controls>
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            className="photo1"
            src={productImage}
            alt="product"
          />
        )}
      </div>
      
      <div className="main_right">
      <div>
       
        <h3>Watch Video</h3>
        <button 
          className={`Video ${selectedVideo === 'Video' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('video', 'Video')}
          onClick={() => handleVideoClick('Video')}>
           Video
        </button>
        {getVideoMessage()}
      </div>
        <h3>Choose your model</h3>
        <button
          className={`Model1 ${selectedModel === 'Model1' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('model', 'Model1')}
          onClick={() => handleModelClick('Model1')}>
          Classic
        </button>
        <button
          className={`Model2 ${selectedModel === 'Model2' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('model', 'Model2')}
          onClick={() => handleModelClick('Model2')}>
          Advanced
        </button>
        <p>{getModelMessage()}</p>
      

        {selectedModel && (
        <div>
         {selectedModel === 'Model1' && (
          <div>
            <h3>Hear the alarm sound</h3>
            <p><b>Caution!</b> Ensure the volume is safe to prevent hearing damage; prioritize safety while adjusting to a comfortable level.</p>
            <button
              className={`Model1_sound1 ${selectedSound === 'Model1_sound1' ? 'selected' : ''}`}
              style={getCategoryButtonStyle('sound', 'Model1_sound1')}
              onClick={() => handleSoundClick('Model1_sound1')}>
               1 Meter
            </button>
            <button
              className={`Model1_sound2 ${selectedSound === 'Model1_sound2' ? 'selected' : ''}`}
              style={getCategoryButtonStyle('sound', 'Model1_sound2')}
              onClick={() => handleSoundClick('Model1_sound2')}>
               5 Meter
            </button>
            <button
              className={`Model1_sound3 ${selectedSound === 'Model1_sound3' ? 'selected' : ''}`}
              style={getCategoryButtonStyle('sound', 'Model1_sound3')}
              onClick={() => handleSoundClick('Model1_sound3')}>
               10 Meter
            </button>
            <p>{getSoundMessage()}</p>
          </div>
        )}
        

          {selectedModel === 'Model2' && (
            <div>
              <h3>Hear the alarm sound</h3>
              <p><b>Caution!</b> Ensure the volume is safe to prevent hearing damage; prioritize safety while adjusting to a comfortable level.</p>
              <button
                className={`Model2_sound1 ${selectedSound === 'Model2_sound1' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('sound', 'Model2_sound1')}
                onClick={() => handleSoundClick('Model2_sound1')}>
                1 Meter
              </button>
              <button
                className={`Model2_sound2 ${selectedSound === 'Model2_sound2' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('sound', 'Model2_sound2')}
               onClick={() => handleSoundClick('Model2_sound2')}>
                5 Meter
              </button>
              <button
                className={`Model2_sound3 ${selectedSound === 'Model2_sound3' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('sound', 'Model2_sound3')}
               onClick={() => handleSoundClick('Model2_sound3')}>
                10 Meter
              </button>
              <p>{getSoundMessage()}</p>
            </div>
          )}
          
           <div>
           <h3>Choose your girofar option</h3>
           <button
             className={`Girofar_Enable ${selectedGirofar === 'Girofar_Enable' ? 'selected' : ''}`}
             style={getCategoryButtonStyle('girofar', 'Girofar_Enable')}
             onClick={() => handleGirofarClick('Girofar_Enable')}>
             Girofar Enable
           </button>
           <button
             className={`Girofar_Disable ${selectedGirofar === 'Girofar_Disable' ? 'selected' : ''}`}
             style={getCategoryButtonStyle('girofar', 'Girofar_Disable')}
             onClick={() => handleGirofarClick('Girofar_Disable')}>
             Girofar Disable
           </button>
           {getGirofarMessage()}
         </div>
          
          
         
          {selectedModel === 'Model2' && (
            <div>
              <h3 className="wire_title">Choose your wire length option</h3>
              <button
                className={`Model2_wire1 ${selectedWire === 'Model2_wire1' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire1')}
                onClick={() => handleWireClick('Model2_wire1')}>
                0.24 meters
              </button>
              <button
                className={`Model2_wire2 ${selectedWire === 'Model2_wire2' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire2')}
                onClick={() => handleWireClick('Model2_wire2')}>
                1 meters
              </button>
              <button
                className={`Model2_wire3 ${selectedWire === 'Model2_wire3' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire3')}
                onClick={() => handleWireClick('Model2_wire3')}>
                3 meters
              </button>
              <button
                className={`Model2_wire4 ${selectedWire === 'Model2_wire4' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire4')}
                onClick={() => handleWireClick('Model2_wire4')}>
                5 meters
              </button>
              <button
                className={`Model2_wire5 ${selectedWire === 'Model2_wire5' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire5')}
                onClick={() => handleWireClick('Model2_wire5')}>
                7 meters
              </button>
              <button
                className={`Model2_wire6 ${selectedWire === 'Model2_wire6' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire6')}
                onClick={() => handleWireClick('Model2_wire6')}>
                10 meters
              </button>
              {/* <button
                className={`Model2_wire7 ${selectedWire === 'Model2_wire7' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire7')}
                onClick={() => handleWireClick('Model2_wire7')}>
                14 meters
              </button>
              <button
                className={`Model2_wire8 ${selectedWire === 'Model2_wire8' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire8')}
                onClick={() => handleWireClick('Model2_wire8')}>
                17 meters
              </button>
              <button
                className={`Model2_wire9 ${selectedWire === 'Model2_wire9' ? 'selected' : ''}`}
                style={getCategoryButtonStyle('wire', 'Model2_wire9')}
                onClick={() => handleWireClick('Model2_wire9')}>
                20 meters
              </button> */}
             
              <p>{getWireMessage()}</p>
            </div>
          )}
        </div>
      )}
      
      

      <div>
        <h3>Choose your battery option</h3>
        <button
          className={`Battery_Enable ${selectedBattery === 'Enable' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('battery', 'Battery_Enable')}
          onClick={() => handleBatteryClick('Battery_Enable')}>
          Battery Enable
        </button>
        <button
          className={`Battery_Disable ${selectedBattery === 'Battery_Disable' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('battery', 'Battery_Disable')}
          onClick={() => handleBatteryClick('Battery_Disable')}>
          Battery Disable
        </button>
        {getBatteryMessage()}
      </div>

      {/* <div>
        <h3>Choose your solar panel option</h3>
        <button
          className={`Solar_Enable ${selectedSolarPanel === 'Solar_Enable' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('solarPanel', 'Solar_Enable')}
          onClick={() => handleSolarPanelClick('Solar_Enable')}>
          Solar enable
        </button>
        <button
          className={`Solar_Disable ${selectedSolarPanel === 'Solar_Disable' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('solarPanel', 'Solar_Disable')}
          onClick={() => handleSolarPanelClick('Solar_Disable')}>
          Solar disable
        </button>
        {getSolarPanelMessage()}
      </div> */}

     

      <div>
        <h3>Choose your postage option</h3>
        <button
          className={`Postage_Enable ${selectedPostage === 'Postage_Enable' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('postage', 'Postage_Enable')}
          onClick={() => handlePostageClick('Postage_Enable')}>
          Postage Enable
        </button>
        <button
          className={`Postage_Disable ${selectedPostage === 'Postage_Disable' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('postage', 'Postage_Disable')}
          onClick={() => handlePostageClick('Postage_Disable')}>
          Postage Disable
        </button>
        {getPostageMessage()}
      </div>

      {/* <div>
        <h3>Choose your installation option</h3>
        <button
          className={`Installation_Enable ${selectedInstallation === 'Installation_Enable' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('installation', 'Installation_Enable')}
          onClick={() => handleInstallationClick('Installation_Enable')}>
          Installation Enable
        </button>
        <button
          className={`Installation_Disable ${selectedInstallation === 'Installation_Disable' ? 'selected' : ''}`}
          style={getCategoryButtonStyle('installation', 'Installation_Disable')}
          onClick={() => handleInstallationClick('Installation_Disable')}>
          Installation Disable
        </button>
        {getInstallationMessage()}
      </div> */}

      <br></br>
      <div>
        <button className="Calculate"  onClick={handleCalculateClick}>Calculate</button>
      </div>
      <br></br>
      <div>
        <p><b>Selected Codes:</b> {selectedCodes}</p>
        <p>Total Cost: <b>Rs {selectedPrice}</b></p> 
        <p><b>{ModelSelection}</b></p>
        <p><b>1 year</b> warranty guaranteed after sales service support by professional technician</p>

      </div>
          <br></br>
      <div>
        <h3>How to order?</h3>
        <p>Please contact us on <b>Whatsapp Number:+230 57803931</b> with the <b>code number</b> displayed when pressing on the calculated button. We will promptly revert back to you, including mode of payment. Thank you! </p>
      </div>
    </div>
  </div>
  );
};

export default Main;
