import React from 'react';





const UseTitle = () => {
  return (
    <div className="use_title">
      <h2>SOS Alert!!! at your finger tips</h2>
      
    </div>
  );
};

export default UseTitle;
