import React from 'react';

import '../style/location.css';
import {location} from  '../asset';

const Location = () => {
    return (
      <div className="location_container" id="location_container">
        <div className="location_left">
          <img src= {location} alt="Map Location" />
        </div>
        <div className="location_right">
        
        <h1>Location</h1>   
        <p><strong>Address:</strong> Shop & Save Express, Royal Road Rose-Belle</p>
        <p><strong>Phone/WhatsApp:</strong> +230 57803931</p>
        <p><strong>Email:</strong> safetywatchmu@gmail.com</p>
        
        </div>
      </div>
    );
  }
  
  export default Location;
  