import React from 'react';

import '../style/intro.css';
import { intro, more_detailAdt } from '../asset';
const Intro = () => {
    return (
      <div className="intro_container">
        <div className="intro_left">
          <img  className="largeScreen2" src={intro} alt="product" />
          <img  className="smallScreen2" src={more_detailAdt} alt="product" />
        </div>
        <div className="intro_right">
          <h1>"Fortify Your Safety with</h1>
          <h1>Avant-garde Security Devices"</h1>
          <h3>-No internet No problem!!!</h3>
          <h3>-Easy installation</h3>
          
        </div>
      </div>
    );
  }
  
  export default Intro;
  