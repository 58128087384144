import React, { useState, useEffect } from 'react';
import '../style/advertisement.css'; // Import your CSS file for styling
import { advertising1 ,advertising2,advertising3,cclamp} from '../asset';
const Advertisement = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    // advertising1,
    cclamp,
    advertising2,
    advertising3
    // Add more image URLs as needed
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentImageIndex, images.length]);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className="advertisement-container">
      <div className="slideshow">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Advertisement ${index + 1}`}
            className={
              index === currentImageIndex
                ? 'advertisement-image active'
                : 'advertisement-image'
            }
          />
        ))}
        <div className="dots-container">
          {images.map((_, index) => (
            <div
              key={index}
              className={
                index === currentImageIndex
                  ? 'dot active-dot'
                  : 'dot'
              }
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Advertisement;