/* Navbar.js */

import React from 'react';
import '../style/footer.css';

const Footer = () => {
  

    return (
        <footer className="footer">
           
            <p>Copyright © 2023 Satety Watch Inc. All rights reserved.</p>  
      
        </footer>
    );
}

export default Footer;
