import React from 'react';
import {bedridden,burgler,deliveryVan,stealing} from '../asset';
import '../style/use.css';

const images = [bedridden, burgler,deliveryVan,stealing]; // Add more image paths if needed

const textData = [
  {"title": "Bedridden Alert", "message": "Never be alone. Immediate assistance for medical or personal needs."},
  {"title": "Burglar Alert", "message": "Protect home with quick-trigger alarm. Deter intruders and alert authorities."},
  {"title": "Delivery Van Alert", "message": "Prevent theft in your van. Use one-touch alarm to deter thieves and alert surroundings."},
  {"title": "Stealing Alert", "message": "Secure your business. Quick response ensures deterrent and prioritizes safety."},
];



const Use = () => {
  return (
    <div className="use_container">
      <div className="use">
        {images.map((image, index) => (
          <div key={index} className="image_container">
            <img  className="UselargeScreen2" src={image} alt={`product-${index}`} />
            <div className="text_container">
              <h3>{textData[index].title}</h3>
              <p>{textData[index].message}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Use;
