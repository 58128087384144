import React from 'react';
import '../style/aboutUs.css';


const AboutUs = () => {
    return (
        <div className="about-us-container" id ="about-us-container">
            <h2>About Us</h2>
            <p>
                Safety Watch, a startup born in 2023, is passionately dedicated to enhancing the safety and well-being of individuals. Our mission is to provide cutting-edge solutions that empower people to lead secure lives.
            </p>
            <p>
                Safety Watch is committed to creating a safer environment for our clients through innovation and reliability.
            </p>
            <p>
                Beyond our services, Safety Watch is driven by a passion for community safety. We believe that everyone deserves to live and work in a secure environment, and our solutions are designed with this principle in mind.
            </p>
            <p>
                Join us in our mission to make the world a safer place. Safety Watch – your dynamic partner in protection.
            </p>
        </div>
    );
};

export default AboutUs;
