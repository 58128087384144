import React, { useState } from 'react';
import '../style/other_product.css';
import productsData from '../json/product.json'; 

const OtherProduct = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);

    return (
      <div className="Product_container" id="Product_container">
        <h1>Other Products</h1>
        <p>Please contact us on <b>Whatsapp Number:+230 57803931</b> with the <b>code number</b> displayed when pressing on the calculated button. We will promptly revert back to you, including mode of payment. Thank you! </p>
        <h2>Create a Seamless Home Automation Experience by Integrating All Your EZVIZ Products into a Tailored Smart Home Security Solution</h2>

        <div className="product-list">
          {Object.keys(productsData.other_products).map((key) => {
            const product = productsData.other_products[key];
            return (
              <div key={key} className="product-card">
                <img
                  src={product.photoProduct}
                  alt={product.title}
                  onClick={() => setSelectedProduct(product)}
                />
                <h3>{product.title}</h3>
                <h5>{product.description}</h5>
                <p>Before Price: Rs <s><b>{product.beforePrice}</b></s></p>
                <p>Price: Rs <b>{product.price}</b></p>
                <p>Product Code: <b>{product.product_code}</b></p>
                <p>Availability: <b>{product.availability}</b></p>
              </div>
            );
          })}
        </div>
  
        {/* Display selected product details */}
        {selectedProduct && (
          <div className="selected-product">
            <h2>Selected Product Details</h2>
            <img src={selectedProduct.photoProduct} alt={selectedProduct.title} />
            <p>{selectedProduct.description}</p>
            <p>Price: ${selectedProduct.price}</p>
            <p>Product Code: {selectedProduct.product_code}</p>
          </div>
        )}
      </div>
    );
  };
  
  export default OtherProduct;