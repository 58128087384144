/* Navbar.js */

import { logo } from '../asset';
import React, { useState } from 'react';
import '../style/navbar.css';

const Navbar = () => {
    const [showNav, setShowNav] = useState(false);
    const handleScrollToMain = (e) => {
        e.preventDefault();
        const mainSection = document.getElementById('main_container');
        if (mainSection) {
          mainSection.scrollIntoView({ behavior: 'smooth' });
        }
      };

      const handleScrollToOtherProduct = (e) => {
        e.preventDefault();
        const productSection = document.getElementById('Product_container');
        if (productSection) {
          productSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
      
      const handleScrollToLocation = (e) => {
        e.preventDefault();
        const locationSection = document.getElementById('location_container');
        if (locationSection) {
          locationSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
      const handleScrollToAboutUs = (e) => {
        e.preventDefault();
        const locationSection = document.getElementById('location_container');
        if (locationSection) {
          locationSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
      
      

    const toggleNav = () => {
        setShowNav(!showNav);
    };

    return (
        <nav>
            <div className="navbar-container">
                <div className="logo-container">
                    <img src={logo} alt="Company Logo" />
                    <span>Safety Watch</span>
                </div>
                <ul className={`nav-links ${showNav ? 'show-nav' : ''}`}>
                    <li>
                        <a href="/shopNow" onClick={handleScrollToMain}>Shop Now</a>
                    </li>
                    <li>
                        <a href="/otherProduct" onClick={handleScrollToOtherProduct}>Other Product</a>
                    </li>
                    <li>
                        <a href="/location" onClick={handleScrollToLocation}>Location</a>
                    </li>
                    <li>
                        <a href="/aboutUs" onClick={handleScrollToAboutUs}>About Us</a>
                    </li>
                </ul>
                <span className="hamburger-icon" onClick={toggleNav}>
                    {showNav ? '✕' : '☰'}
                </span>
            </div>
        </nav>
    );
}

export default Navbar;
